import {COLORS, Typography} from '@fupa/fupa-uikit';
import {FuPaTv, GalleryAndTv} from 'app/components/icons';
import {Collections, PhotoCamera} from '@mui/icons-material';
import React from 'react';
import styled from 'styled-components';

const getMatchRowColor = (match, ownClubSlug, ownTeamSlug) => {
  const decidingSlug = ownClubSlug || ownTeamSlug;
  const decider = ownClubSlug ? 'clubSlug' : 'slug';

  if (!(match.homeTeam && match.awayTeam) || match.homeGoal === match.awayGoal) {
    return COLORS.lighterGrey;
  } else if (match.homeGoal > match.awayGoal) {
    return match.homeTeam[decider] === decidingSlug ? COLORS.lightGreen : COLORS.lightRed;
  } else {
    return match.awayTeam[decider] === decidingSlug ? COLORS.lightGreen : COLORS.lightRed;
  }
};

const getMatchMediaFlags = (flags, defaultSize = 14) => {
  const iconColor = COLORS.darkGrey;
  let icon = null;
  if (flags) {
    if (flags.includes('fupatv') && flags.includes('gallery')) {
      icon = <GalleryAndTv style={{color: iconColor, fontSize: defaultSize + 6}} />;
    } else if (flags.includes('fupatv')) {
      icon = <FuPaTv style={{color: iconColor, fontSize: defaultSize - 2}} />;
    } else if (flags.includes('gallery')) {
      icon = <PhotoCamera style={{color: iconColor, fontSize: defaultSize}} />;
    }
  }
  return icon;
};

const getMatchMedia = (flags, defaultSize = 16) => {
  const iconColor = COLORS.white;
  let icon = null;
  let teaser = null;
  if (flags?.includes('gallery')) {
    icon = <Collections style={{color: iconColor, fontSize: defaultSize}} />;
    teaser = '/matchrow_photo_teaser_3.png';
  }

  return {icon, teaser};
};

const getCompetitorKey = (ownTeamSlug, match) => {
  return match?.homeTeam?.slug === ownTeamSlug ? 'awayTeam' : 'homeTeam';
};
const LabelTypo = styled(Typography)`
  line-height: 1;
`;

const getMatchTimeFinishedInfo = matchFlags => {
  const additionalInfoConfig = {
    penalty: 'n.E.',
    overtime: 'n.V.',
  };

  const flags = ['penalty', 'overtime'];
  const infoFlag = matchFlags?.find(flag => {
    if (flags.includes(flag)) {
      return flag;
    }
  });

  return infoFlag ? <LabelTypo variant={'caption1'}>{additionalInfoConfig[infoFlag]}</LabelTypo> : null;
};

const getMatchStatusAndProgress = match => {
  const hasResult = typeof match.homeGoal === 'number' || typeof match.awayGoal === 'number';
  const PRE_STATUS = match.section === 'PRE' && !hasResult;
  // match is live but has no liveticker nor goals
  const LIVE_NO_TICKER_STATUS =
    match.section === 'LIVE' && !hasResult && !match.flags?.includes('ticker') && !match.flags?.includes('softticker');
  const LIVE_STATUS =
    match.section === 'LIVE' && match.live && (match.flags?.includes('ticker') || match.flags?.includes('softticker'));
  const POST_STATUS = match.section === 'POST' || hasResult;

  const matchStatus = PRE_STATUS
    ? 'PRE_STATUS'
    : LIVE_NO_TICKER_STATUS
    ? 'LIVE_NO_TICKER_STATUS'
    : LIVE_STATUS
    ? 'LIVE_STATUS'
    : POST_STATUS
    ? 'POST_STATUS'
    : '';
  let liveProgress;
  if (LIVE_STATUS) {
    const minute = match.live.minute;
    const regular = match.live.regular;
    liveProgress = minute < regular ? (minute / regular) * 100 : 100;
  }

  return {matchStatus, liveProgress};
};

export {
  getMatchRowColor,
  getMatchMediaFlags,
  getMatchMedia,
  getCompetitorKey,
  getMatchTimeFinishedInfo,
  getMatchStatusAndProgress,
};
