import React from 'react';
import {formatDayMonthDate, formatTime} from 'app/helpers/dateHelpers';
import styled from 'styled-components';
import {FlexContainer} from 'app/styles/content';
import {COLORS, SIZES, Typography} from '@fupa/fupa-uikit';

const Kickoff = styled(FlexContainer)`
  align-self: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${SIZES['56']};
  height: ${SIZES['30']};
`;

const MatchStatus = styled(Typography)`
  &&& {
    line-height: 1.4;
    color: ${COLORS.black};
  }
`;

const LiveTypography = styled(Typography)`
  &&& {
    line-height: 1.25;
  }
`;

const MatchRowKickoff = ({match, dynamicDateformat}) => {
  const liveTicker =
    match.flags && match.flags.includes('ticker') ? <LiveTypography variant='caption1'>live</LiveTypography> : null;
  let pregameStatus;
  const curDate = new Date();
  const kickoffDate = new Date(match.kickoff);

  if (
    !dynamicDateformat ||
    (curDate.getFullYear() === kickoffDate.getFullYear() &&
      curDate.getMonth() === kickoffDate.getMonth() &&
      curDate.getDate() === kickoffDate.getDate())
  ) {
    pregameStatus = formatTime(match.kickoff);
  } else if (kickoffDate.getTime() - curDate.getTime() < 518400000) {
    // Spiel ist in den nächsten 5 Tagen (Wenn heute Mittwoch -> Spiel am Montag)
    const days = ['So.', 'Mo.', 'Di.', 'Mi.', 'Do.', 'Fr.', 'Sa.'];
    pregameStatus = days[kickoffDate.getDay()];
  } else {
    pregameStatus = formatDayMonthDate(match.kickoff);
  }

  return (
    <Kickoff>
      <MatchStatus variant='caption3'>{pregameStatus}</MatchStatus>
      {liveTicker}
    </Kickoff>
  );
};

export {MatchRowKickoff};
